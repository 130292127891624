.label {
  font-size: 12px;
  font-weight: 600;

  &[data-checked] {
    color: theme('colors.black');
    border: 1px solid theme('colors.blue.6');
  }

  &[data-variant="filled"] {
    background-color: transparent;
  }
}

:global(.dark) {
  .label {
    &[data-checked] {
      color: theme('colors.white');
      border: 1px solid theme('colors.blue.8');
    }
  }
}
